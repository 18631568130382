<template>
  <v-menu
    bottom
    origin="center center"
    transition="scale-transition"
    >
    <template v-slot:activator="{ on, attrs }">
        <v-btn
        color="primary"
        dark
        v-bind="attrs"
        v-on="on"
        >
        Language
        </v-btn>
    </template>

    <v-list>
        <v-list-item v-for="(item, i) in items" :key="i" >
        <v-list-item-title @click="changeLocale(i)">{{ item.title }}</v-list-item-title>
        </v-list-item>
    </v-list>
    </v-menu>
</template>

<script>
  export default {
    name: 'LanguageSwitcher',

    data: () => ({
      items: [
      { title: 'Укараїнська', code:'uk' },
      { title: 'Русский', code: 'ru' },
      { title: 'English', code: 'en' },
    ]}),
    methods: {
        changeLocale: function(index) {
            const lang = this.items[index].code
            this.$vuetify.lang.current = lang;
            this.$i18n.locale = lang;
        }
    }
  }
</script>
